import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import { Segment } from 'appTypes';
import { isComparisonMonth } from 'features/task_bar/ComparisonPicker';
import { formatComparisonMonthName } from 'features/chart/nrtChartCommon';

export function nrtCommonInfo(
  targetDate: string,
  comparison: string,
  segment: Segment,
  uses_middle_east_day_types,
) {
  let comparisonFmt = comparison;
  if (isComparisonMonth(comparison)) {
    comparisonFmt = formatComparisonMonthName(
      comparison,
      targetDate,
      uses_middle_east_day_types,
    );
  }
  return [
    segment.properties.cardinal_dir
      ? `Direction, ${segment.properties.cardinal_dir}`
      : null,
    `Date, ${targetDate}`,
    `Comparison, ${comparisonFmt}`,
    `Access date, ${dayjs().format('YYYY-MM-DD')}`,
  ];
}

export function triggerCsvExport(filename, contents) {
  const blob = new Blob(
    [`\ufeff${contents.filter((i) => i !== null).join('\r\n')}`],
    {
      type: 'text/plain;charset=utf-8',
    },
  );
  FileSaver.saveAs(blob, filename);
}
