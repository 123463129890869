import dayjs from 'dayjs';
import Highcharts from 'highcharts';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import {
  baseChartOptions,
  getBandMembership,
  grid_color,
  label_color,
  axis_color,
  font_size,
  graph_highlight_color,
  day_grid_color,
} from './chartOptions';
import { isComparisonMonth } from '../task_bar/ComparisonPicker';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const TICK_INTERVAL_HOUR = 1000 * 60 * 60; // milliseconds * seconds * minutes

// highcharts-react npm page
export const BIN_WIDTH_MINUTES_TO_MS = 60 * 1000;

export function getFormattedTime(ts) {
  return dayjs(ts).format('HH:mm');
}

export function formatTargetSeriesName(targetDate) {
  return `${dayjs(targetDate).format('ddd D MMM')}`;
}

export function getDowWord(
  dow: number,
  uses_middle_east_day_types: boolean,
  longform: boolean = false,
) {
  let dowWord = 'Weekdays';
  if (uses_middle_east_day_types) {
    if (dow === 5) {
      dowWord = longform ? 'Fridays' : 'Fris';
    } else if (dow === 6) {
      dowWord = longform ? 'Saturdays' : 'Sats';
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (dow === 0) {
      dowWord = longform ? 'Sundays' : 'Suns';
    } else if (dow === 6) {
      dowWord = longform ? 'Saturdays' : 'Sats';
    }
  }
  return dowWord;
}

export function formatComparisonMonthName(
  comparison,
  target,
  uses_middle_east_day_types,
) {
  const dow = dayjs(target).day();
  const dowWord = getDowWord(dow, uses_middle_east_day_types);
  return `${dowWord} ${dayjs(comparison, 'MMMM-YYYY').format("MMM 'YY")}`;
}

export const formatTypicalSeriesName = (
  comparison: string,
  targetDate: string,
  uses_middle_east_day_types: boolean,
  current_ts?: number,
) => {
  if (!comparison) {
    return '';
  }

  if (isComparisonMonth(comparison)) {
    return formatComparisonMonthName(
      comparison,
      current_ts || targetDate,
      uses_middle_east_day_types,
    );
  }

  // Assume the comparison is a day, if current_ts is present, display the offset from current_ts (dependent on hover)
  if (current_ts) {
    const offset = dayjs(comparison).diff(dayjs(targetDate));
    const comparisonDisplayDate = dayjs(current_ts).add(offset);
    return formatTargetSeriesName(dayjs(comparisonDisplayDate));
  }

  // Static display
  return formatTargetSeriesName(comparison);
};

export const makeFormatTooltip = (
  bands,
  bin_size,
  uses_middle_east_day_types: boolean,
  value_formatter,
  targetDate: string,
  comparison: string,
) => {
  function formatTooltip(
    this: Highcharts.TooltipFormatterContextObject,
    tooltip: Highcharts.Tooltip,
  ) {
    const ts = (this.points[1]?.x || this.points[0]?.x) as number;
    const date = dayjs(ts).format('HH:mm');
    const band = getBandMembership(ts, bands);
    const end_time = dayjs(ts + bin_size * BIN_WIDTH_MINUTES_TO_MS).format(
      'HH:mm',
    );
    let output = `
        <div class="chart-legend" style="font-size: 15px;">
            <h1>${date} - ${end_time}</h1>
            <table>`;
    if (band) {
      // console.log(band);
      // Justifying FF0E: https://kschaul.com/til/2022/09/13/coloring-emoji-unicode-characters-on-ios-safari/
      output += `
      <tr>
        <td>
            <span class="highlight-icon" style="color:${graph_highlight_color}">◼&#xFE0E;</span>Highlight
        </td>
        <td>
            <b>${getFormattedTime(band.raw_from)} - ${getFormattedTime(
        band.raw_to,
      )}</b>
        </td>
      </tr>`;
    }
    if (this.points[1]) {
      output += `
        <tr>
            <td>
                <span style="color:${
                  this.points[1].point.color
                }">●</span>${formatTargetSeriesName(ts)}
            </td>
            <td>
                <b>${value_formatter(this.points[1].y)}</b>
            </td>
        </tr>`;
    }
    if (this.points[0]) {
      output += `
        <tr>
            <td>
                <span style="color:${
                  this.points[0].point.color
                }">●</span>${formatTypicalSeriesName(
        comparison,
        targetDate,
        uses_middle_east_day_types,
        ts,
      )}
            </td>
            <td>
                <b>${value_formatter(this.points[0].y)}</b>
            </td>
        </tr>`;
    }
    return `${output}</table></div>`;
  }
  return formatTooltip;
};

export const nrtXAxisOptions = {
  type: 'datetime',
  dateTimeLabelFormats: {
    day: '%H:%M',
  },
  minPadding: 0,
  maxPadding: 0,
  // startOnTick: true,
  endOnTick: true,
  lineColor: axis_color,
  gridLineColor: grid_color, // vertical lines
  minorGridLineColor: grid_color,
  gridLineWidth: 1,
  tickPixelInterval: 50,
  tickInterval: TICK_INTERVAL_HOUR * 3,
  minorTickInterval: TICK_INTERVAL_HOUR,
  labels: {
    style: {
      color: label_color,
      fontSize: font_size,
    },
  },
  title: {
    text: 'Time of day',
    style: {
      color: label_color,
    },
  },
};

export const dayMarkerLineBase = {
  color: day_grid_color,
  width: 3,
};
