/* eslint-disable max-classes-per-file */

import { selectorFromId } from 'features/common/utils';

export enum ToggleLayerControlValue {
  true = 1,
  false = 0,
  disabled = 'disabled',
}

export class ToggleLayerControl {
  // #map: any;
  #container: any;
  #widgetId: string;
  title?: string;
  initialValue?: ToggleLayerControlValue;
  clickHandler?: (ev) => void;
  static TRUE_VALUE_CLASS = 'true';
  static DISABLED_CLASS = 'disabled';

  constructor(
    widgetId: string,
    title?: string,
    initialValue?: boolean | ToggleLayerControlValue,
    clickHandler?: (ev) => void,
  ) {
    this.#widgetId = widgetId;
    this.title = title;
    this.initialValue = ToggleLayerControl.sanitiseValue(initialValue);

    this.clickHandler = clickHandler;
  }

  static sanitiseValue(
    value: boolean | ToggleLayerControlValue,
  ): ToggleLayerControlValue {
    if (value === true || value === undefined) {
      return ToggleLayerControlValue.true;
    } else if (value === false) {
      return ToggleLayerControlValue.false;
    } else {
      return value;
    }
  }

  static valueToClass(value: ToggleLayerControlValue) {
    if (value === ToggleLayerControlValue.true) {
      return ToggleLayerControl.TRUE_VALUE_CLASS;
    } else if (value === ToggleLayerControlValue.disabled) {
      return ToggleLayerControl.DISABLED_CLASS;
    } else {
      return '';
    }
  }

  onAdd(map) {
    // console.log('ToggleLayerControl onAdd');
    // this.#map = map;
    this.#container = document.createElement('div');
    this.#container.className = 'showhide mapboxgl-ctrl mapboxgl-ctrl-group';
    const title = this.title ? `title="${this.title}"` : '';
    const realized_class = ToggleLayerControl.valueToClass(this.initialValue);
    this.#container.innerHTML = `<button id="${
      this.#widgetId
    }" class="${realized_class}" ${title}><span class="mapboxgl-ctrl-icon"></span></button>`;
    if (this.clickHandler) {
      this.#container.addEventListener('click', this.clickHandler);
    }
    return this.#container;
  }

  onRemove() {
    // console.log('ToggleLayerControl onRemove');
    this.#container.parentNode.removeChild(this.#container);
    // this.#map = undefined;
  }

  static getElement(widgetSelectorId: string) {
    return document.querySelector(selectorFromId(widgetSelectorId));
  }

  static getValue(widgetSelectorId: string) {
    const element = ToggleLayerControl.getElement(widgetSelectorId);
    if (element) {
      return element.classList.contains(ToggleLayerControl.TRUE_VALUE_CLASS);
    } else {
      return undefined;
    }
  }

  static setValue(value, widgetSelectorId: string) {
    const element = ToggleLayerControl.getElement(widgetSelectorId);
    if (element) {
      element.className = ToggleLayerControl.valueToClass(
        ToggleLayerControl.sanitiseValue(value),
      );
    }
  }

  static setTitle(value: string, widgetSelectorId: string) {
    const element = ToggleLayerControl.getElement(widgetSelectorId);
    if (element) {
      element.setAttribute('title', value);
    }
  }
}

export class SettingsControl {
  // #map: any;
  #container: any;
  #widgetId: string;
  title?: string;
  handler;

  constructor(widgetId: string, handler, title?: string) {
    this.#widgetId = widgetId;
    this.title = title;
    this.handler = handler;
  }

  onAdd(map) {
    this.#container = document.createElement('div');
    this.#container.className = 'showhide mapboxgl-ctrl mapboxgl-ctrl-group';
    const title = this.title ? `title="${this.title}"` : '';
    this.#container.innerHTML = `<button id="${
      this.#widgetId
    }" class="enabled"><span class="mapboxgl-ctrl-icon" ${title}></span></button>`;
    this.#container.addEventListener('click', this.handler);
    return this.#container;
  }

  onRemove() {
    this.#container.parentNode.removeChild(this.#container);
  }
}
