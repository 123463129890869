import {
  stl_background_color,
  stl_blue_color,
  stl_gold_color,
} from 'theme/cemTheme';
import {
  kMainVisLineOpacity,
  kSegmentHoverId,
  mapLayerSafeRemove,
  mapSourceSafeRemove,
  thick_line_width_expression,
} from 'features/map/mapCommon';
import {
  bboxFromPointBuffer,
  findNearestSegmentLatLng,
  setLayerVisibility,
  visibleProperty,
} from 'features/map/mapUtils';
import mapboxgl, { PointLike } from 'mapbox-gl';
import { capitalizeFirstLetter } from 'features/task_bar/SegmentInfo';
import { isRealtimeMetric } from 'features/workflow_timeline/metricsOptions';

export const kIncidentPoILayerId = 'incidents_poi'; // Little icons
export const kIncidentFlowLayerId = 'incidents_flow'; // closure snakes
export const kIncidentSourceId = 'incidents';

export const kIncidentsShowHideNormalMessage =
  'Show/Hide Realtime Incidents layer';
export const kIncidentsShowHideDisabledMessage =
  'Incidents layer only available for realtime';

const magnitude_coloring = [
  'match',
  ['get', 'magnitude'],
  0,
  stl_gold_color,
  1,
  stl_gold_color,
  2,
  '#daad3c',
  3,
  '#d2791f',
  4,
  '#E00000',
  stl_blue_color,
];

const hide_all_but_closures = [
  'match',
  ['get', 'icon_category_0'],
  7,
  true,
  8,
  true,
  9,
  true,
  11,
  true,
  false,
];

const hide_all_clusters = [
  'match',
  ['coalesce', ['get', 'cluster_id'], -1],
  -1,
  true,
  false,
];

export const kIncidentLayerDefinition = {
  id: kIncidentPoILayerId,
  type: 'symbol',
  source: kIncidentSourceId,
  'source-layer': 'Traffic incident POI',
  metadata: 'group:incidents_marker',
  minzoom: 10,
  filter: [
    // Hide endpoints unless it's a closure
    'match',
    ['get', 'poi_type'],
    'end_poi',
    hide_all_but_closures,
    hide_all_clusters,
  ],
  paint: {
    'icon-color': magnitude_coloring,
    'icon-halo-color': stl_background_color,
    'icon-halo-width': 4,
  },
  layout: {
    'icon-allow-overlap': false,
    'icon-anchor': 'center',
    'icon-ignore-placement': true,
    'icon-image': [
      'match',
      ['coalesce', ['get', 'icon_category_0'], ['get', 'icon_category']],
      0,
      'default',
      1, // Accident
      'traffic',
      2, // Fog
      'default',
      3, // Dangerous conditions
      'default',
      4, // Rain
      'default',
      5, // Ice
      'default',
      6, // Jam
      'traffic',
      7, // Lane closed
      'closed',
      8, // Road closed
      'closed',
      9, // Road works
      'roadworks',
      10, // Wind
      'default',
      11, // Flooding
      'default',
      14, // Broken down vehicle
      'default',
      'default',
    ],
    'symbol-avoid-edges': true,
    'symbol-placement': 'point',
  },
};

export const kIncidentFlowLayerDefinition = {
  id: kIncidentFlowLayerId,
  type: 'line',
  source: kIncidentSourceId,
  'source-layer': 'Traffic incident flow',
  filter: hide_all_but_closures,
  layout: {},
  paint: {
    'line-color': magnitude_coloring,
    'line-width': thick_line_width_expression,
    'line-dasharray': [0, 1, 1],
    'line-opacity': kMainVisLineOpacity,
  },
};

export function shouldShowIncidents(newShow, metric) {
  return newShow && isRealtimeMetric(metric);
}

export function setIncidentLayerVisibility(map, newShow, metric) {
  setLayerVisibility(
    shouldShowIncidents(newShow, metric),
    kIncidentPoILayerId,
    map,
  );
  setLayerVisibility(
    shouldShowIncidents(newShow, metric),
    kIncidentFlowLayerId,
    map,
  );
}

export function redrawIncidents(
  map: mapboxgl.Map,
  project_slug: string,
  startVisible: boolean,
) {
  mapLayerSafeRemove(map, kIncidentPoILayerId);
  mapLayerSafeRemove(map, kIncidentFlowLayerId);
  mapSourceSafeRemove(map, kIncidentSourceId);
  const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/server/tiles/incidents/${project_slug}/{z}/{x}/{y}/`;
  map.addSource(kIncidentSourceId, {
    type: 'vector',
    tiles: [url],
  });
  const flowLayer = { ...kIncidentFlowLayerDefinition } as any;
  flowLayer.layout = { ...flowLayer.layout };
  flowLayer.layout.visibility = visibleProperty(startVisible);
  map.addLayer(flowLayer);
  const poiLayer = { ...kIncidentLayerDefinition } as any;
  poiLayer.layout = { ...poiLayer.layout };
  poiLayer.layout.visibility = visibleProperty(startVisible);
  map.addLayer(poiLayer);
}

export function findNearestIncidentInBox(map: mapboxgl.Map, point) {
  const bbox = bboxFromPointBuffer(point, 8);
  const features = map.queryRenderedFeatures(bbox, {
    layers: [kIncidentPoILayerId],
  });
  // We split it up because mapboxgl has much worse performance for some reason when you have multiple layers
  features.push(
    ...map.queryRenderedFeatures(bbox, { layers: [kIncidentFlowLayerId] }),
  );
  if (features.length > 0) {
    for (let i = 0; i < features.length; i++) {
      console.log(i, features[i].geometry, features[i].properties);
    }
    return features[0]; // TODO get closest
  }
  return undefined;
}
