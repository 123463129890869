import React from 'react';

import { TravelTimeChart } from 'features/chart/TravelTimeChart';

import { WorkflowRecent } from 'features/workflow_nrt/WorkflowRecent';
import { RouteSegmentInfo } from 'features/task_bar/RouteSegmentInfo';
import { ExportTravelTimesButton } from 'features/workflow_traveltime/ExportTraveltimesButton';
import {
  WORKFLOW_SPEED,
  WORKFLOW_TIMELINE,
  WORKFLOW_TRAVEL_TIME,
} from '../../appConstants';
import {
  getSpecificWorkflowState,
  getWorkflowState,
} from '../../state/workflowSlice';
import { NoRouteSelectedPanel } from './NoRouteSelectedPanel';

export class workflowTravelTime {
  switchToState(fromWorkflow, state) {
    // console.log('Handling switch from ', fromWorkflow, 'to', state.current, state);
    if (
      [WORKFLOW_TIMELINE, WORKFLOW_SPEED, WORKFLOW_TRAVEL_TIME].includes(
        fromWorkflow,
      )
    ) {
      getWorkflowState(state).targetDate = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).targetDate;
      getWorkflowState(state).comparison = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).comparison;
    }
  }
}

export function WorkflowTravelTime() {
  return (
    <WorkflowRecent
      chart={<TravelTimeChart />}
      graph_title="Travel Time"
      enableRouteSelection
      segmentInfoExtras={[<RouteSegmentInfo />]}
      chartSettingsExtras={<ExportTravelTimesButton />}
      unselected_chart_state_fn={NoRouteSelectedPanel}
    />
  );
}
