import React from 'react';
import { createRoot } from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'state/store';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { Login } from 'features/login/Login';
import reportWebVitals from 'reportWebVitals';
import { AppDispatcher } from 'app/AppDispatcher';
import { App, AppUrlChangeListener } from 'app/App';
import { Terms } from 'features/login/Terms';

import './index.css';
import theme, { CemThemeProvider } from './theme/cemTheme';
import { HeapAnalytics } from './features/heapAnalytics/heapAnalyticsService';
import { MapIconProvider } from './features/map/MapIconProvider';
import { ErrorPage, ReactErrorBoundary } from './app/ErrorHandling';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppDispatcher />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app',
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/terms',
    element: <Terms />,
    errorElement: <ErrorPage />,
  },
]);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HeapAnalytics />
      <CemThemeProvider>
        <CssBaseline />
        <PersistGate loading={<div>loading...</div>} persistor={persistor}>
          <ReactErrorBoundary>
            <AppUrlChangeListener />
            <RouterProvider router={router} />
          </ReactErrorBoundary>
        </PersistGate>
      </CemThemeProvider>
    </Provider>
    <MapIconProvider />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
