import React, { useContext } from 'react';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
  selectSegmentId,
  selectCurrentProjectInfo,
  selectComparison,
  selectTargetDate,
} from 'state/workflowSlice';
import { useGetSegmentInfoQuery } from 'state/apiSlice';

import { nrtCommonInfo, triggerCsvExport } from 'features/common/exportUtils';
import { useSpeedsDataProvider } from 'features/workflow_nrt/nrtSpeedsCommon';
import { ChartDataExpander } from 'features/chart/ChartDataExpander';
import { formatSpeed, speedUnit } from 'features/common/utils';
import {
  BIN_WIDTH_MINUTES_TO_MS,
  getFormattedTime,
} from 'features/chart/nrtChartCommon';

import { ExportButton } from 'features/task_bar/ExportButton';
import styles from 'features/task_bar/Taskbar.module.css';
import { LayerContext } from 'state/LayerContext';
import { isOnDay, parseDate } from 'appUtils';

export function ExportSpeedsButton() {
  const comparison = useSelector(selectComparison);
  const targetDate = useSelector(selectTargetDate);
  const segmentId = useSelector(selectSegmentId);
  const userProject = useSelector(selectCurrentProjectInfo);

  // cached so no need to memoize
  const { layer } = useContext(LayerContext);
  const { currentData: segmentData } = useGetSegmentInfoQuery(
    { layerId: layer, routeIds: [segmentId] },
    { skip: !layer || !segmentId },
  );

  const segment = segmentData && segmentData?.segments[segmentId];

  // typicalData from REST api
  const { speedData, comparisonSpeedData } = useSpeedsDataProvider();

  const handleClick = (event) => {
    const dataExpander = new ChartDataExpander(targetDate);
    const comparisonExpander = new ChartDataExpander(targetDate, comparison);
    const data = dataExpander.expandSpeeds(speedData, userProject.uses_metric);
    const expandedComparisonData = comparisonExpander.expandSpeeds(
      comparisonSpeedData,
      userProject.uses_metric,
    );
    const unit = speedUnit(userProject.uses_metric);
    const csv = [
      `Name, ${segment.properties.name}`,
      `ID, ${segmentId}`,
      ...nrtCommonInfo(
        targetDate,
        comparison,
        segment,
        userProject.uses_middle_east_day_types,
      ),
      segment.properties.freeflow_speed_mph
        ? `Freeflow speed, ${formatSpeed(
            segment.properties.freeflow_speed_mph,
            userProject.uses_metric,
          )}`
        : null,
      '',
      '',
      `Time Start, Time End, ${targetDate} Speed (${unit}), ${comparison} Speed (${unit})`,
    ];
    const format = (v) => {
      if (v || v === 0) {
        return Math.round(v).toString();
      } else {
        return '';
      }
    };
    for (let i = 0; i < data.length; i += 1) {
      const row = data[i];
      const compRow = expandedComparisonData[i];
      const time = row.x;
      const endtime = time + speedData.bin_size * BIN_WIDTH_MINUTES_TO_MS;
      if (isOnDay(time, targetDate)) {
        csv.push(
          `${getFormattedTime(time)},${getFormattedTime(endtime)},${format(
            row.y,
          )},${format(compRow.y)}`,
        );
      }
    }
    // console.log(`ExportVolumeButton segmentVolumes ${JSON.stringify(data)}`);
    const formattedDate = parseDate(targetDate).format('MMDDYYYY');
    triggerCsvExport(`speeds_${formattedDate}_${segmentId}.csv`, csv);
  };

  const isActive = !!speedData && !!comparisonSpeedData;

  return (
    <div className={styles.task_bar_component_bottom}>
      <ExportButton isActive={isActive} handleClick={handleClick} />
    </div>
  );
}
