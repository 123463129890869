import { formatSpeed, KM_TO_MILES } from 'features/common/utils';
import { formatTimeOfDay } from 'features/workflow_timeline/slotsUtils';
import {
  isRealtimeMetric,
  isVNRTMetric,
} from 'features/workflow_timeline/metricsOptions';
import { capitalizeFirstLetter } from 'features/task_bar/SegmentInfo';

export function formatSegmentInfoToTableRows(
  feature,
  usesMetric,
  timeOfDay,
  timeOfDayOffset,
  timelineMetric,
) {
  const NA = 'No sample';

  function formatSpeeds(value) {
    if (!value || value.toString() === '-1') {
      return NA;
    }
    return formatSpeed(value * KM_TO_MILES, usesMetric);
  }

  function formatCounts(value) {
    if (!value) {
      return NA;
    }
    return value.toString().split('.')[0];
  }

  function indexIntoProperty(featurex, prefix) {
    const prop_key = `${prefix}${timeOfDayOffset}`;
    const value = featurex?.properties[prop_key] || featurex?.state[prop_key];
    if (!value || value === '-1') {
      return undefined;
    } // else {
    //   // @ts-ignore: Use array destructuring
    //   // eslint-disable-next-line
    //   value = value.split('.')[0];
    // }
    return value.toString();
  }

  const time = formatTimeOfDay(timeOfDay);

  const counts = indexIntoProperty(feature, 'c');
  const speeds = indexIntoProperty(feature, 's');
  const typicalCounts = indexIntoProperty(feature, 'tc');
  const typicalSpeeds = indexIntoProperty(feature, 'ts');
  const rtSpeed = formatSpeeds(feature.state.rtspeed);
  const freeflowSpeed = formatSpeeds(
    feature.properties.freeflow_speed_kph ||
      feature.properties.vnrt_freeflow_kph,
  );
  let description = '';
  if (isRealtimeMetric(timelineMetric)) {
    description += `
            <tr><td>Realtime speed</td><td>${rtSpeed}</td></tr>
            <tr><td>Comparison speed</td><td>${formatSpeeds(
              typicalSpeeds,
            )}</td></tr>
            <tr class="space-under"><td>Freeflow speed</td><td>${freeflowSpeed}</td></tr>`;
  } else if (isVNRTMetric(timelineMetric)) {
    description += `
            <tr><td><b>Speed at ${time}</b></td><td><b>${formatSpeeds(
      speeds,
    )}</b></td></tr>
            <tr><td>Comparison speed</td><td>${formatSpeeds(
              typicalSpeeds,
            )}</td></tr>
            ${
              rtSpeed !== NA
                ? `<tr><td>Realtime speed</td><td>${rtSpeed}</td></tr>`
                : ''
            }
            <tr class="space-under"><td>Freeflow speed</td><td>${freeflowSpeed}</td></tr>`;
  } else {
    description += `
            <tr><td><b>Speed at ${time}</b></td><td><b>${formatSpeeds(
      speeds,
    )}</b></td></tr>
            <tr><td>Comparison speed</td><td>${formatSpeeds(
              typicalSpeeds,
            )}</td></tr>
            <tr class="space-under"><td>Freeflow speed</td><td>${freeflowSpeed}</td></tr>
            <tr><td><b>Trip count</b></td><td><b>${formatCounts(
              counts,
            )}</b></td></tr>
            <tr><td>Comparison count</td><td>${formatCounts(
              typicalCounts,
            )}</td></tr>`;
  }
  return description;
}

export function formatIncidentInfoToTableRows(incident) {
  let description = '';
  const inc_descs = [];
  if (incident.properties.description) {
    inc_descs.push(incident.properties.description);
  }
  for (let i = 0; i < 10; i++) {
    const sub_desc = incident.properties[`description_${i}`];
    if (sub_desc) {
      inc_descs.push(sub_desc);
    }
  }
  description += `
  <tr><td>Incident</td><td>${inc_descs.join(', ')}</td></tr>`;
  if (incident.properties.traffic_road_coverage) {
    description += `<tr><td>Coverage</td><td>${capitalizeFirstLetter(
      incident.properties.traffic_road_coverage.replace('_', ' '),
    )}</td></tr>`;
  }
  const { delay } = incident.properties;
  if (delay !== undefined && delay !== 0) {
    let delay_formatted = `${delay} secs`;
    if (delay > 60) {
      delay_formatted = `${Math.floor(delay / 60)} mins`;
    }
    description += `<tr><td>Delay</td><td>${delay_formatted}</td></tr>`;
  }
  return description;
}

export function formatInfoToHtml(
  feature,
  incident,
  usesMetric,
  timeOfDay,
  timeOfDayOffset,
  timelineMetric,
) {
  let description = '';
  if (feature) {
    description += `<h1>${feature.properties.name}</h1>`;
  }
  description += `<table>`;
  if (feature) {
    description += formatSegmentInfoToTableRows(
      feature,
      usesMetric,
      timeOfDay,
      timeOfDayOffset,
      timelineMetric,
    );
  }
  if (incident) {
    description += formatIncidentInfoToTableRows(incident);
  }
  description += '</table>';
  return description;
}
